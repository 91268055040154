import { Button, Form, Input, Modal, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function CreateNote({
  onOpenCreateNoteModal,
  onCancelCreateNoteModal,
  onCreateNote,
  onEditNote,
  onInputChange,
  setNote,
  editNote,
  note,
}) {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);

  const cancelModal = () => {
    setSelectedPrerequisites([]);
    onCancelCreateNoteModal();
  };

  useEffect(() => {
    setIsEditMode(editNote && Object.keys(editNote).length > 0);
  }, [editNote]);

  useEffect(() => {
    if (isEditMode) {
      setNote(editNote);
    }
  }, [isEditMode]);

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Note" : "New Note"}
        centered
        destroyOnClose={true}
        open={onOpenCreateNoteModal}
        onOk={isEditMode ? onEditNote : onCreateNote}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={isEditMode ? onEditNote : onCreateNote}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            {isEditMode ? "Save" : "Add Note"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Note Item" required>
            <TextArea
              style={{ height: "80px" }}
              name="note"
              placeholder="Note"
              onChange={onInputChange}
              value={note && note.note ? note.note : ""}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateNote;
