import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Select,
  Form,
  Input,
  Row,
  Spin,
  message,
} from "antd";
import { Country, State } from "country-state-list";
import EventService from "../../../services/EventService";
import CourseService from "../../../services/CourseService";
import FetchClient from "../../../serviceClients/FetchClient";
const { TextArea } = Input;

function EventInformation({ setActiveTab }) {
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [shippingStateDisabled, setShippingStateDisabled] = useState(true);
  const [statesList, setStatesList] = useState(true);
  const [error, setError] = useState({});
  const [courses, setCourses] = useState([]);
  const [selectedType, setSelectedType] = useState({});
  const [countries, setCountries] = useState([]);
  const [isClassSelected, setIsClassSelected] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const eventTypeOptions = [
    { id: 1, label: "Class", value: "Class" },
    { id: 2, label: "Event", value: "Event" },
  ];
  const classTypeOptions = [
    { id: 1, label: "On Demand", value: "ondemand" },
    { id: 2, label: "Live Streamed", value: "live" },
    { id: 3, label: "In Person", value: "person" },
    { id: 4, label: "Hybrid", value: "hybrid" },
  ];
  const selectedEvent = location.state?.event

  useEffect(() => {
    if (selectedEvent) {
      form.setFieldsValue(selectedEvent);
      console.log(selectedEvent);
      
      if (selectedEvent.eventType == "Class") {
        setIsClassSelected(true)
      }
    }
  },[selectedEvent])

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleFormChange = () => {};

  function onCountryChange(value) {
    if (value.toLowerCase() === "usa") {
      setShippingStateDisabled(false);
      let states = State.getStatesOfCountry("US");
      setStatesList(states.map(state => {
        return {
          label: state.name,
          value: state.name
        }
      }));
    } else if (value.toLowerCase() === "can") {
      setShippingStateDisabled(false);
      let states = State.getStatesOfCountry("CA");
      setStatesList(states.map(state => {
        return {
          label: state.name,
          value: state.name
        }
      }));
    } else {
      setShippingStateDisabled(true);
      setStatesList([]);
    }
  }

  const onEventTypeChange = (value) => {
    if (value === "Class") {
      setIsClassSelected(true);
    } else {
      setIsClassSelected(false);
    }
    setSelectedType(value);
  };

  const onFinish = async (values) => {
    console.log('values', values);
    setIsLoading(true);

    // Call Service to update event data
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _eventService = new EventService(_fetchClient);

    try {
      let res;
      let eventId = localStorage.getItem("eventId")
      if (eventId) {
        res = await _eventService.updateEvent({...values, id: eventId});
      } else {
        res = await _eventService.createEvent(values);
      }
      console.log("res", res);
      localStorage.setItem("eventId", res.id)

      navigate(".", { state: { eventData: res } });
      messageApi.open({
        type: "success",
        content: "Event profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      setActiveTab(2);
    }
  };

  //GET COURSE LIST
  async function fetchCourses() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const courseService = new CourseService(_fetchInstance);

    try {
      const courseData = await courseService.getAllCourses();
      setCourses(
        courseData.map((course) => {
          return {
            label: course.name,
            value: course.id,
          };
        })
      );
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchCourses();
    if (countries != []) {
      setCountries(
        Country.getAllCountries().map((country) => {
          return country.isoCode == "US"
            ? {
                label: country.name,
                value: "usa",
              }
            : country.isoCode == "CA"
            ? {
                label: country.name,
                value: "can",
              }
            : {
                label: country.name,
                value: country.name,
              };
        })
      );
    }
  }, []);

  const [form] = Form.useForm();

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="accountDetailsForm"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="eventType" label="Event Type">
              <Select
                name="eventType"
                className="userListSearchBox"
                onChange={onEventTypeChange}
                options={eventTypeOptions}
                allowClear
                value={selectedType}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            {isClassSelected && (
              <Form.Item name="classType" label="Class Type">
                <Select
                  name="classType"
                  className="userListSearchBox"
                  onChange={handleFormChange}
                  options={classTypeOptions}
                  allowClear
                ></Select>
              </Form.Item>
            )}
          </Col>
          <Col span={12}>
            {isClassSelected && (
              <Form.Item name="course" label="Course">
                <Select
                  mode="multiple"
                  name="course"
                  className="userListSearchBox"
                  onChange={handleFormChange}
                  options={courses}
                  allowClear
                ></Select>
              </Form.Item>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item rules={[
                    { required: true, message: "Please enter an event code" },
                  ]} name="code" label="Event Code">
                  <Input onChange={handleFormChange} defaultValue={""} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  label="Event Name"
                  name="eventName"
                  rules={[
                    { required: true, message: "Please enter an event type" },
                  ]}
                >
                  <Input onChange={handleFormChange} defaultValue={""} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="description" label="Description" required>
                  <TextArea
                    style={{ height: "180px" }}
                    name="description"
                    placeholder="Description"
                    onChange={handleFormChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            {isClassSelected && (
              <>
                <strong>Prerequisites</strong>
                {courses.map((course) => {
                  return (
                    <>
                      <br></br>
                      <span>{course.label}</span>
                    </>
                  );
                })}
              </>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="country" label="Country">
              <Select
                name="country"
                showSearch
                optionFilterProp="label"
                className="userListSearchBox"
                onChange={onCountryChange}
                options={countries}
                allowClear
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Address 1"
              name="address1"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Address 2"
              name="address2"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="City"
              name="city"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
          {!shippingStateDisabled && (
            <Col span={6}>
              <Form.Item name="state" label="State">
                <Select
                  name="state"
                  className="userListSearchBox"
                  showSearch
                  optionFilterProp="label"
                  onChange={handleFormChange}
                  options={statesList}
                  allowClear
                ></Select>
              </Form.Item>
            </Col>
          )}
          <Col span={6}>
            <Form.Item
              label="Zip Code"
              name="zipCode"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button htmlType="submit" className="confirmButton">
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default EventInformation;
