const config = require("../config");

class CourseService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getAllCourses() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllCourses?"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch courses.");
      }

      const courses = await response.json();
      return courses;
    } catch (error) {
      console.error("Error fetching courses: ", error);
      return [];
    }
  }


  async createCourse(course) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateCourse",
        course
      );
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async batchUpdateCourses(coursesActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchCourseUpdate",
        coursesActionModel
      );

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateCourse(course) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateCourse",
        course
      );
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  async deleteCourse(course) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/DeleteCourseById",
        course
      );
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updatePassword(passwordModel) {
    try {
      await this.httpClient.post(
        this.BASE_URL + "/UpdatePassword",
        passwordModel
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default CourseService;
