import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Spin,
  message,
  Select,
} from "antd";
import EventService from "../../../services/EventService";
import FetchClient from "../../../serviceClients/FetchClient";
import CreateFacilitator from "../../../components/ClassesEvents/CreateFacilitator";
const { TextArea } = Input;

function Facilitator({ setActiveTab }) {
  const location = useLocation()
  const { instance } = useMsal();
  const [displayedFacilitators, setDisplayedFacilitators] = useState([]);
  const [facilitators, setFacilitators] = useState([]);
  const [error, setError] = useState({});
  const [teamFacilitators, setTeamFacilitators] = useState([]);
  const [temporalFacilitator, setTemporalFacilitator] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(location.state?.event);
  const [facilitatorTeams, setFacilitatorTeams] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createFacilitatorModal, setCreateFacilitatorModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const eventId = localStorage.getItem("eventId")


  useEffect(() => {
    if (!selectedEvent) {
      fetchEvent();
    }
  }, []);

  useEffect(() => {
    console.log('selectedEvent', selectedEvent);
    
    form.setFieldsValue(selectedEvent);
  },[selectedEvent])

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  async function fetchEvent() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const eventService = new EventService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const eventData = await eventService.getEvent(eventId);
      setSelectedEvent(eventData[0]);
      form.setFieldsValue(eventData[0]);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }


  const handleFormChange = () => {};
  const deselectFacilitator = (e) => {
    setDisplayedFacilitators(
      displayedFacilitators.filter((facilitator) => e != facilitator)
    );
  };

  const onCreateFacilitator = () => {
    setDisplayedFacilitators([
      ...displayedFacilitators,
      temporalFacilitator.label,
    ]);
    if (temporalFacilitator.type == "team") {
      setTeamFacilitators([...teamFacilitators, temporalFacilitator.value]);
    } else {
      setFacilitators([...facilitators, temporalFacilitator.value]);
    }
    setCreateFacilitatorModal(false);
  };
  const handleCreateFacilitatorInputChange = () => {
    console.log("");
  };

  const onCloseFacilitatorCreateModal = () => {
    setCreateFacilitatorModal(false);
  };

  const onFinish = async (values) => {
    console.log(values);
    setIsLoading(true);

    //Call Service to update user data
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _eventService = new EventService(_fetchClient);

    let eventId = localStorage.getItem("eventId");
    const updatedEvent = {
      ...values,
      teamFacilitators: teamFacilitators,
      facilitators: facilitators,
      id: eventId,
    };

    try {
      let res = await _eventService.updateEvent(updatedEvent);

      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsLoading(false);
    } catch (error) {
      console.log(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      setActiveTab(3)
    }
  };

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="facilitatorsForm"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={11}>
            <Form.Item
              label="Facilitators"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Select
                value={displayedFacilitators}
                mode="multiple"
                onDeselect={deselectFacilitator}
                onChange={handleFormChange}
              />
            </Form.Item>
          </Col>
          <Col span={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setCreateFacilitatorModal(true)}
              style={{ marginTop: 30 }}
            >
              +
            </Button>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Contact Name"
              name="contactName"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Phone"
              name="contactPhone"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email"
              name="contactEmail"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              label="Additional contact information"
              name="contactAdditionalInfo"
              required
            >
              <TextArea
                style={{ height: "100px" }}
                name="contactAdditionalInfo"
                placeholder="Additional contact information"
                onChange={() => console.log("")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Organizer"
              name="organizer"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Assistant"
              name="assistant"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Sponsor"
              name="sponsor"
              rules={[
                { required: true, message: "Please enter an event type" },
              ]}
            >
              <Input onChange={handleFormChange} defaultValue={""} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button onClick={() => setActiveTab(1)} className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button htmlType="submit" className="confirmButton">
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <CreateFacilitator
        onOpenCreateFacilitatorModal={createFacilitatorModal}
        onCancelCreateFacilitatorModal={onCloseFacilitatorCreateModal}
        onCreateFacilitator={onCreateFacilitator}
        onInputChange={handleCreateFacilitatorInputChange}
        displayedFacilitators={displayedFacilitators}
        setDisplayedFacilitators={setDisplayedFacilitators}
        setTemporalFacilitator={setTemporalFacilitator}
        facilitatorTeams={facilitatorTeams}
        setFacilitatorTeams={setFacilitatorTeams}
      />
    </Card>
  );
}

export default Facilitator;
