import { Button, Form, Modal, Select, Spin } from "antd";
import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import UserService from "../../services/UserService";
import FacilitatorService from "../../services/FacilitatorService";
import FetchClient from "../../serviceClients/FetchClient";


function CreateFacilitator({
  onOpenCreateFacilitatorModal,
  onCancelCreateFacilitatorModal,
  onCreateFacilitator,
  setTemporalFacilitator,
}) {
  const [form] = Form.useForm();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isTeamSelected, setIsTeamSelected] = useState(false);
  const [selectValues, setSelectValues] = useState([]);

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const cancelModal = () => {
    onCancelCreateFacilitatorModal();
  };

  const onInputChange = async (e) => {
    setIsLoading(true)
    if (e == "Individual") {
      setIsTeamSelected(false);
      await fetchUsers()
    } else {
      setIsTeamSelected(true);
      await fetchFacilitators()
    }
    setIsLoading(false)

  };
  const onUserOrTeamSelected = async (e) => {
    setIsLoading(true)
    setTemporalFacilitator({...e, type: isTeamSelected ? "team" : "user"})
    setIsLoading(false)
  };

  async function fetchUsers() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const userService = new UserService(_fetchInstance);

    try {
      const userData = await userService.getUsers();
      setSelectValues(
        userData.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }
  async function fetchFacilitators() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const facilitatorService = new FacilitatorService(_fetchInstance);

    try {
      const facilitatorData = await facilitatorService.getAllFacilitators();
      setSelectValues(
        facilitatorData.map((facilitator) => {
          return {
            label: facilitator.name,
            value: facilitator.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={"New Facilitator"}
        centered
        destroyOnClose={true}
        open={onOpenCreateFacilitatorModal}
        onOk={onCreateFacilitator}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={onCreateFacilitator}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            {"Add Facilitator"}
          </Button>,
        ]}
      >
        <Spin spinning={isLoading} fullscreen size="large" />
        <Form form={form} layout="vertical">
          <Form.Item label="Type" required>
            <Select
              name="type"
              placeholder="Type"
              onChange={onInputChange}
              options={[
                { label: "Team", value: "Team" },
                { label: "Individual", value: "Individual" },
              ]}
            />
          </Form.Item>
          <Form.Item label="Facilitator" required>
            <Select
              name="facilitator"
              showSearch
              labelInValue
              optionFilterProp="label"
              disabled={selectValues.length == 0}
              placeholder="Facilitator"
              onChange={onUserOrTeamSelected}
              options={selectValues}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateFacilitator;
