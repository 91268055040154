export const SubHeaderOptions = [
  { title: "HOME", path: "/", icon: "" },
  { title: "ABOUT US", path: "/aboutUs", icon: "" },
  { title: "COURSES", path: "/courses", icon: "" },
  { title: "CLUBS", path: "/clubs", icon: "" },
  { title: "STORE", path: "/store", icon: "" },
  { title: "CITY", path: "/city", icon: "" },
  { title: "CONTACT", path: "contact", icon: "" },
];

export const ProfileHeaderOptions = [
  { title: "Profile", path: "/myProfile", icon: "" },
  { title: "Family Members", path: "/", icon: "" },
  { title: "Dues", path: "/", icon: "" },
  { title: "Classes & Events", path: "/", icon: "" },
  { title: "Club Membership", path: "/", icon: "" },
];
