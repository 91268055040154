import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import moment from "moment"
import { ExclamationCircleFilled } from "@ant-design/icons";
import ParticipantService from "../../../services/ParticipantService";
import FetchClient from "../../../serviceClients/FetchClient";
import { Card, Flex, Input, Space, Table, Button, message, Row, Col } from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { renderMatches, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateParticipant from "../../../components/ClassesEvents/CreateParticipant";

function Participants({setActiveTab}) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [participant, setParticipant] = useState({
    feePaid: "0",
    credit: "0"
  });
  const [participants, setParticipants] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [editParticipant, setEditParticipant] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createParticipanteModal, setCreateParticipantModal] = useState(false);
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [50, 100, 200],
    },
  });
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET COURSE LIST
  async function fetchParticipants() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const participantService = new ParticipantService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const participantData = await participantService.getAllParticipants(eventId);
      setParticipants(participantData);
      setAllParticipants(participantData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, total: participantData.length },
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteParticipant() {
    setIsLoading(true);
    setSelectedParticipants([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _participantService = new ParticipantService(_fetchInstance);

    try {
      //DELETE USER
      await _participantService.deleteParticipant(selectedParticipants[0]);
      messageApi.open({
        type: "success",
        content: "Participant deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      fetchParticipants();
    }
  }

  useEffect(() => {
    fetchParticipants();
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setParticipants([]);
    }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = (record) => {
    setEditParticipant(record)
    setCreateParticipantModal(true)
  };

  //SET COURSE LIST TABLE COLUMNS
  const columns = [
    {
      title: "Participant",
      dataIndex: "userId",
      key: "userId",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.userId?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.userId.localeCompare(b.userId),
      render: (_, record) => (
        <>
          <span>{record.user?.firstName + ' ' + record.user?.firstName}</span>
        </>
      )
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.role?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.role.localeCompare(b.role),
    },
    {
      title: "Fee Paid",
      dataIndex: "feePaid",
      key: "feePaid",
      filteredValue: [nameSearchText],
      render: (_, record) => (
        <>
          <span>{record.feePaid ? "Yes" : "No"}</span>
        </>
      )
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.paymentDate?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.paymentDate.localeCompare(b.paymentDate),
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      filteredValue: [nameSearchText],
      render: (_, record) => (
        <>
          <span>{record.credit ? "Yes" : "No"}</span>
        </>
      )
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this participant?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        deleteParticipant();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseParticipantCreateModal = () => {
    setEditParticipant({});
    setParticipant({});
    setCreateParticipantModal(false);
  };


  const onCreateParticipant = async () => {
    setIsLoading(true);
    setEditParticipant({});
    setParticipant({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _participantService = new ParticipantService(_fetchInstance);
    let createdParticipant = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      createdParticipant = await _participantService.createParticipant({...participant, eventId: eventId});
      messageApi.open({
        type: "success",
        content: "Participant created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseParticipantCreateModal();
      setIsLoading(false);
      fetchParticipants();
    }
  };
  const onEditParticipant = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _participantService = new ParticipantService(_fetchInstance);
    let createdParticipant = {};

    try {
      //CREATE USER
      createdParticipant = await _participantService.updateParticipant(participant);
      messageApi.open({
        type: "success",
        content: "Participant updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseParticipantCreateModal();
      setIsLoading(false);
      fetchParticipants();
    }
  };
  const handleCreateParticipantInputChange = (e) => {
    const { name, value, id } = e.target;
    setParticipant({ ...participant, [name]: id ? id : value });
  };
  const handleCreateParticipantSelectChange = (e) => {
    setParticipant({
      ...participant,
      userId: e,
    });
  };
  const handleCreateParticipantDateInputChange = (e) => {
    const date = new Date(e);
    setParticipant({
      ...participant,
      paymentDate: moment(date).format('L'),
    });
  };

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedParticipants(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Participant",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <Space size="small" align="center">
                  <Input.Search
                    placeholder="Name"
                    className="courseListSearchBox"
                    onSearch={(value) => {
                      setNameSearchText(value);
                    }}
                    onChange={(e) => {
                      setNameSearchText(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={nameSearchText}
                  />
                  {selectedParticipants.length === 1 && (
                    <Button
                      onClick={showDeleteModal}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    onClick={() => setCreateParticipantModal(true)}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Add Participant Item +
                  </Button>
                </Space>
              </Space>
              <Table
                rowClassName={"tableRowActiveParticipant"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={participants}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
              <CreateParticipant
                onOpenCreateParticipantModal={createParticipanteModal}
                onCancelCreateParticipantModal={onCloseParticipantCreateModal}
                onEditParticipant={onEditParticipant}
                onCreateParticipant={onCreateParticipant}
                onInputChange={handleCreateParticipantInputChange}
                onSelectChange={handleCreateParticipantSelectChange}
                onDateInputChange={handleCreateParticipantDateInputChange}
                setParticipant={setParticipant}
                participant={participant}
                allParticipants={participants}
                editParticipant={editParticipant}
              />
            </div>
          </Flex>
        </Flex>
        <Row gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button onClick={() => setActiveTab(3)} className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button onClick={() => setActiveTab(5)} htmlType="submit" className="confirmButton">
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Participants;
