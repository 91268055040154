import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Radio,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  familyStatusOptions,
  membershipOptions,
  prospectSourceOptions,
  roleOptions,
} from "../../shared/selectOptions";
import { useMsal } from "@azure/msal-react";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

function Membership({
  userData,
  isSaveDisabled,
  setIsSaveDisabled,
  loggedUser,
  isSameAdminUser
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [membershipStatus, setMembershipStatus] = useState("nonMember");
  const [lifetimeMember, setLifetimeMember] = useState(
    userData.lifetimeMember
  );
  const [lifetimeMembershipDate, setLifetimeMembershipDate] = useState();
  const [membershipDateVisible, setMembershipDateVisible] = useState(
    lifetimeMember === "1" ? "block" : "none"
  );
  const [role, setRole] = useState("user");
  const [familyStatus, setFamilyStatus] = useState(null);
  const [receiveMail, setReceiveMail] = useState(userData.receiveMail);
  const [prospectSource, setProspectSource] = useState(null);

  //STATE VARIABLES FOR PERMISSION VALIDATIONS
  const [membershipStatusEnabled, setMembershipStatusEnabled] = useState(true);
  const [roleEnabled, setRoleEnabled] = useState("block");
  const [lifetimeMemberEnabled, setLifetimeMemberEnabled] = useState(true);
  const [lifeTimeMemberDateEnabled, setLifeTimeMemberDateEnabled] =
    useState(true);
  const [familyStatusEnabled, setFamilyStatusEnabled] = useState(true);
  const [receiveMailEnabled, setReceiveMailEnabled] = useState(true);
  const [prospectSourceEnabled, setProspectSourceEnabled] = useState("block");
  const [activeAccount, setActiveAccount] = useState({});
  
  useEffect(() => {
    if (instance) {
      setActiveAccount(instance.getActiveAccount())
      //CHECK USER ROLE FOR PERMISSIONS
      if (isSameAdminUser) {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setLifeTimeMemberDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      } else if (loggedUser.role.toLowerCase() === "admin") {
        setMembershipStatusEnabled(true);
        setRoleEnabled("block");
        setLifetimeMemberEnabled(true);
        setLifeTimeMemberDateEnabled(true);
        setFamilyStatusEnabled(true);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("block");
      } else if (loggedUser.role.toLowerCase() === "user") {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setLifeTimeMemberDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      } else if (loggedUser.role.toLowerCase() === "teachingcoordinator") {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setLifeTimeMemberDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      } else if (loggedUser.role.toLowerCase() === "clubadmin") {
        setMembershipStatusEnabled(false);
        setRoleEnabled("none");
        setLifetimeMemberEnabled(false);
        setLifeTimeMemberDateEnabled(false);
        setFamilyStatusEnabled(false);
        setReceiveMailEnabled(true);
        setProspectSourceEnabled("none");
      }
    }
  }, [instance, loggedUser.role]);

  const onMembershipChange = (value) => {
    setMembershipStatus(value);
    setIsSaveDisabled(false);
  };

  const onLifetimeChange = (e) => {
    console.log("lifetime checked: ", e.target.value);
    setLifetimeMember(e.target.value);
    setIsSaveDisabled(false);

    if (e.target.value === "1") {
      setMembershipDateVisible("block");
    } else {
      setMembershipDateVisible("none");
    }
  };

  const onRoleChange = (value) => {
    setRole(value);
    setIsSaveDisabled(false);
  };

  const onFamilyStatusChange = (value) => {
    setFamilyStatus(value);
    setIsSaveDisabled(false);
  };

  const onReceiveMailChange = (e) => {
    setReceiveMail(e.target.value);
    setIsSaveDisabled(false);
  };

  const onProspectSourceChange = (value) => {
    setProspectSource(value);
    setIsSaveDisabled(false);
  };

  const onChangelifetimeMembershipDateChange = (date, dateString) => {
    console.log(dateString);
    setLifetimeMembershipDate(dateString);
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    console.log(values);

    //BUILD USER OBJECT
    const updateUser = {
      email: userData.email,
      familyStatus: values.familyStatus,
      lifetimeMember: lifetimeMember,
      lifetimeMembershipDate: lifetimeMembershipDate,
      membershipStatus: values.membershipStatus,
      prospectSource: values.prospectSource,
      receiveMail: receiveMail,
      role: values.role,
    };

    //CREATE USER SERVICE INSTANCE
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _userService = new UserService(_fetchClient);

    try {
      //Call Service to update user data
      const resultUser = await _userService.updateUser(updateUser);
      navigate(".", { state: { userData: resultUser } });

      //DISPLAY SUCCESS NOTIFICATION MESSAGE
      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsSaveDisabled(true);
    } catch (error) {
      //LOG ERROR
      console.log(error);

      //DISPLAY ERROR NOTIFICATION
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [form] = Form.useForm();

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        size="large"
        form={form}
        name="membershipDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          membershipStatus: userData.membershipStatus,
          role: userData.role,
          lifetimeMember: userData.lifetimeMember,
          receiveMail: userData.receiveMail,
          familyStatus: userData.familyStatus,
          prospectSource: userData.prospectSource,
          lifetimeMembershipDate: userData.lifetimeMembershipDate
            ? dayjs(userData.lifetimeMembershipDate)
            : null,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Membership Status"
              name="membershipStatus"
              required
            >
              <Select
                options={membershipOptions}
                onChange={onMembershipChange}
                disabled={!membershipStatusEnabled}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Role"
              name="role"
              required
              style={{ display: roleEnabled }}
            >
              <Select options={roleOptions} onChange={onRoleChange}></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Lifetime Member"
              name="lifetimeMember"
              valuePropName="checked"
              required
            >
              <Radio.Group
                onChange={onLifetimeChange}
                value={lifetimeMember}
                disabled={!lifetimeMemberEnabled}
              >
                <Radio value={"1"}>Yes</Radio>
                <Radio value={"0"}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Date"
              name="lifetimeMembershipDate"
              style={{ display: membershipDateVisible }}
            >
              <DatePicker
                onChange={onChangelifetimeMembershipDateChange}
                style={{ width: "300px" }}
                disabled={!lifeTimeMemberDateEnabled}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            {" "}
            <Form.Item label="Family Status" name="familyStatus" required>
              <Select
                options={familyStatusOptions}
                onChange={onFamilyStatusChange}
                disabled={!familyStatusEnabled}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Receive Physical Mail"
              name="receiveMail"
              valuePropName="checked"
              required
            >
              <Radio.Group
                value={receiveMail}
                onChange={onReceiveMailChange}
                disabled={!receiveMailEnabled}
              >
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Prospect Source"
              name="prospectSource"
              style={{ display: prospectSourceEnabled }}
            >
              <Select
                options={prospectSourceOptions}
                onChange={onProspectSourceChange}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} style={{ textAlign: "right" }}>
            <Button
              htmlType="submit"
              className="confirmButton"
              disabled={isSaveDisabled}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default Membership;
