import { Button, Form, Input, Modal } from "antd";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function CreateUser({
  onOpenCreateUserModal,
  onCancelCreateUserModal,
  onCreateUser,
  onInputChange,
  onPhoneNumberChange,
}) {
  const [form] = Form.useForm();

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title="New User"
        centered
        destroyOnClose={true}
        open={onOpenCreateUserModal}
        onOk={onCreateUser}
        onCancel={onCancelCreateUserModal}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={onCancelCreateUserModal}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={onCreateUser}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            Add User
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Email Address (Username)" required>
            <Input
              name="email"
              placeholder="johnDoe@concept-therapy.com"
              onChange={onInputChange}
            />
          </Form.Item>
          <Form.Item label="First Name" required>
            <Input
              name="firstName"
              placeholder="John"
              onChange={onInputChange}
            />
          </Form.Item>
          <Form.Item label="Last Name" required>
            <Input name="lastName" placeholder="Doe" onChange={onInputChange} />
          </Form.Item>
          <Form.Item label="Mobile Number" required>
            <PhoneInput
              country={"us"}
              preferredCountries={["us"]}
              countryCodeEditable={false}
              enableSearch={true}
              onChange={onPhoneNumberChange}
              inputProps={{ name: "mobileNumber", required: true }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateUser;
