import { Button, Flex } from "antd";
import React from "react";
import { SubHeaderOptions } from "./SubHeaderOptions";

function CustomSubHeader() {
  return (
    <Flex align="center" justify="space-between">
      <Flex gap="middle">
        {SubHeaderOptions.map((item, index) => (
          <Button key={index} className="subHeaderMenuItem" type="link">
            {item.title}
          </Button>
        ))}
      </Flex>
    </Flex>
  );
}

export default CustomSubHeader;
