import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import moment from "moment"
import { ExclamationCircleFilled } from "@ant-design/icons";
import ScheduleService from "../../../services/ScheduleService";
import FetchClient from "../../../serviceClients/FetchClient";
import { Card, Flex, Input, Space, Table, Button, message, Row, Col } from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateSchedule from "../../../components/ClassesEvents/CreateSchedule";

function Schedule({setActiveTab}) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [schedule, setSchedule] = useState({});
  const [schedules, setSchedules] = useState([]);
  const [allSchedules, setAllSchedules] = useState([]);
  const [editSchedule, setEditSchedule] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createScheduleeModal, setCreateScheduleModal] = useState(false);
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [50, 100, 200],
    },
  });
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET COURSE LIST
  async function fetchSchedules() {
    // setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const scheduleService = new ScheduleService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const scheduleData = await scheduleService.getAllSchedules(eventId);
      setSchedules(scheduleData);
      setAllSchedules(scheduleData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, total: scheduleData.length },
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteSchedule() {
    setIsLoading(true);
    setSelectedSchedules([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _scheduleService = new ScheduleService(_fetchInstance);

    try {
      //DELETE USER
      await _scheduleService.deleteSchedule(selectedSchedules[0]);
      messageApi.open({
        type: "success",
        content: "Schedule deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      fetchSchedules();
    }
  }

  useEffect(() => {
    fetchSchedules();
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setSchedules([]);
    }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = (record) => {
    setEditSchedule(record)
    setCreateScheduleModal(true)
  };

  //SET COURSE LIST TABLE COLUMNS
  const columns = [
    {
      title: "Schedule Item",
      dataIndex: "item",
      key: "item",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.item?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.item.localeCompare(b.item),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.item?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.item.localeCompare(b.item),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.item?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.item.localeCompare(b.item),
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.item?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.item.localeCompare(b.item),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.item?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.item.localeCompare(b.item),
    },
    {
      title: "Presenter",
      dataIndex: "presenter",
      key: "presenter",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.item?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.item.localeCompare(b.item),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this schedule?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        deleteSchedule();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseScheduleCreateModal = () => {
    setEditSchedule({});
    setSchedule({});
    setCreateScheduleModal(false);
  };

  const onCreateSchedule = async () => {
    setIsLoading(true);
    setEditSchedule({});
    setSchedule({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _scheduleService = new ScheduleService(_fetchInstance);
    let createdSchedule = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      createdSchedule = await _scheduleService.createSchedule({...schedule, eventId: eventId});
      messageApi.open({
        type: "success",
        content: "Schedule created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseScheduleCreateModal();
      setIsLoading(false);
      fetchSchedules();
    }
  };
  const onEditSchedule = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _scheduleService = new ScheduleService(_fetchInstance);
    let createdSchedule = {};

    try {
      //CREATE USER
      createdSchedule = await _scheduleService.updateSchedule(schedule);
      messageApi.open({
        type: "success",
        content: "Schedule updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseScheduleCreateModal();
      setIsLoading(false);
      fetchSchedules();
    }
  };
  const handleCreateScheduleInputChange = (e) => {
    const { name, value } = e.target;
    setSchedule({ ...schedule, [name]: value });
  };
  const handleCreateScheduleDateInputChange = (e) => {
    const firstDate = new Date(e[0]);
    const secondDate = new Date(e[1]);
    setSchedule({
      ...schedule,
      startDate: moment(firstDate).format('L'),
      startTime: moment(firstDate).format('LTS'),
      endDate: moment(secondDate).format('L'),
      endTime: moment(secondDate).format('LTS'),
    });
  };

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedSchedules(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Schedule",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <Space size="small" align="center">
                  <Input.Search
                    placeholder="Name"
                    className="courseListSearchBox"
                    onSearch={(value) => {
                      setNameSearchText(value);
                    }}
                    onChange={(e) => {
                      setNameSearchText(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={nameSearchText}
                  />
                  {selectedSchedules.length === 1 && (
                    <Button
                      onClick={showDeleteModal}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    onClick={() => setCreateScheduleModal(true)}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Add Schedule Item +
                  </Button>
                </Space>
              </Space>
              <Table
                rowClassName={"tableRowActiveSchedule"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={schedules}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
              <CreateSchedule
                onOpenCreateScheduleModal={createScheduleeModal}
                onCancelCreateScheduleModal={onCloseScheduleCreateModal}
                onEditSchedule={onEditSchedule}
                onCreateSchedule={onCreateSchedule}
                onInputChange={handleCreateScheduleInputChange}
                onDateInputChange={handleCreateScheduleDateInputChange}
                setSchedule={setSchedule}
                schedule={schedule}
                allSchedules={schedules}
                editSchedule={editSchedule}
              />
            </div>
          </Flex>
        </Flex>
        <Row gutter={24}>
          <Col span={12}>
            <div style={{ textAlign: "left" }}>
              <Button onClick={() => setActiveTab(2)} className="confirmButton">
                Back
              </Button>
            </div>
          </Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button onClick={() => setActiveTab(4)} htmlType="submit" className="confirmButton">
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Schedule;
