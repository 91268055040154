import { Button, Form, Input, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;

function CreateCourse({
  onOpenCreateCourseModal,
  onCancelCreateCourseModal,
  onCreateCourse,
  onEditCourse,
  onInputChange,
  setCourse,
  editCourse,
  course,
  allCourses,
}) {
  const [form] = Form.useForm();
  const [prerequisitesOptions, setPrerequisitesOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPrerequisites, setSelectedPrerequisites] = useState([]);

  const handlePrerequisitesChange = (value) => {
    setSelectedPrerequisites(value);
    setCourse({ ...course, prerequisites: value });
  };

  const cancelModal = () => {
    setSelectedPrerequisites([]);
    onCancelCreateCourseModal();
  };

  useEffect(() => {
    if (allCourses.length > 0) {
      setPrerequisitesOptions(
        allCourses.map((course) => {
          return {
            label: course.name,
            value: course.id,
          };
        })
      );
    }
  }, [allCourses]);

  useEffect(() => {
    setIsEditMode(editCourse && Object.keys(editCourse).length > 0);
  }, [editCourse]);

  useEffect(() => {
    if (isEditMode) {
      setCourse(editCourse);
      if (editCourse.prerequisites && editCourse.prerequisites.length > 0) {
        setSelectedPrerequisites(
          allCourses
            .filter((course) => editCourse.prerequisites.includes(course.id))
            .map((course) => {
              return {
                label: course.name,
                value: course.id,
              };
            })
        );
      }
    }
  }, [isEditMode]);

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Course" : "New Course"}
        centered
        destroyOnClose={true}
        open={onOpenCreateCourseModal}
        onOk={isEditMode ? onEditCourse : onCreateCourse}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={isEditMode ? onEditCourse : onCreateCourse}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            {isEditMode ? "Save" : "Add Course"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Name" required>
            <Input
              name="name"
              placeholder="Name"
              onChange={onInputChange}
              value={course && course.name ? course.name : ""}
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: 12,
            }}
          >
            <Form.Item style={{ width: "100%" }} label="Code" required>
              <Input
                name="code"
                placeholder="Code"
                onChange={onInputChange}
                value={course && course.code ? course.code : ""}
              />
            </Form.Item>
            <Form.Item style={{ width: "100%" }} label="Order" required>
              <Input
                name="order"
                placeholder="Order"
                onChange={onInputChange}
                value={course && course.order ? course.order : ""}
              />
            </Form.Item>
          </div>
          <Form.Item label="Description" required>
            <TextArea
              style={{ height: "80px" }}
              name="description"
              placeholder="Description"
              onChange={onInputChange}
              value={course && course.description ? course.description : ""}
            />
          </Form.Item>
          <Form.Item label="Prerequisites">
            <Select
              mode="multiple"
              name="prerequisites"
              placeholder="Select Prerequisites"
              className="userListSearchBox"
              onChange={handlePrerequisitesChange}
              options={prerequisitesOptions}
              allowClear
              value={selectedPrerequisites}
            ></Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateCourse;
