import React from "react";
import {
  PersonFillGear,
  PersonCircle,
  PeopleFill,
  CreditCardFill,
  PersonBadge,
  CalendarWeekFill,
  GearFill,
  PersonSquare,
} from "react-bootstrap-icons";
import { BsCalendar2WeekFill } from "react-icons/bs";
import { HiDocumentReport } from "react-icons/hi";
import { HiUserGroup } from "react-icons/hi2";
import { MdOutlineSettingsApplications } from "react-icons/md";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
export const MenuItems = [
  getItem(
    "My Account",
    "myAccount",
    <PersonFillGear />,
    [
      getItem("Profile", "/profile", <PersonCircle />),
      getItem("Family Members", "/familyMembers", <PeopleFill />),
      getItem("Dues", "/dues", <CreditCardFill />),
      getItem("Classes & Events", "/classesAndEvents", <CalendarWeekFill />),
      getItem("Club Membership", "/clubMembership", <PersonBadge />),
    ],
    "group"
  ),
  {
    type: "divider",
  },
];

export const AdminMenuItems = [
  getItem(
    "My Account",
    "myAccount",
    <PersonFillGear />,
    [
      getItem("Profile", "/myProfile", <PersonCircle />),
      getItem("Family Members", "/familyMembers", <PeopleFill />),
      getItem("Dues", "/dues", <CreditCardFill />),
      getItem("Classes & Events", "/classesAndEvents", <CalendarWeekFill />),
      getItem("Club Membership", "/clubMembership", <PersonBadge />),
    ],
    "group"
  ),
  {
    type: "divider",
  },
  getItem(
    "Admin",
    "admin",
    <GearFill />,
    [
      getItem("Users", "users", <PersonSquare />, [
        getItem("User List", "/userList", null),
        getItem("Add User", "/addUser", null),
        getItem("Roles", "/roles", null),
      ]),
      getItem(
        "Classes & Events",
        "classesAndEventsAdmin",
        <BsCalendar2WeekFill />,
        [
          getItem("Classes & Events List", "/classesAndEventsList", null),
          getItem("Applications", "/applications", null),
        ]
      ),
      getItem("Clubs", "clubs", <HiUserGroup />, [
        getItem("Clubs List", "/clubsList", null),
        getItem("Club Events", "/clubEvents", null),
      ]),
      getItem("Reports", "reports", <HiDocumentReport />, []),
      getItem("Settings", "settings", <MdOutlineSettingsApplications />, [
        getItem("Courses", "/courses", null),
        getItem("Templates", "/templates", null),
      ]),
    ],
    "group"
  ),
];
