import { Tabs, Modal, Flex, Button } from "antd";
import React, { useState } from "react";
import EventInformation from "./AddEventTabs/EventInformation";
import Facilitator from "./AddEventTabs/Facilitator";
import Schedule from "./AddEventTabs/Schedule";
import Participants from "./AddEventTabs/Participants";
import Notes from "./AddEventTabs/Notes";
import EventStatus from "./AddEventTabs/EventStatus";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { Header } from "antd/es/layout/layout";
import ProfileHeader from "../../components/ProfileHeader";
import { ExclamationCircleFilled } from "@ant-design/icons";

function ProfileTabs() {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const location = useLocation();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [defaultTab, setDefaultTab] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  let activeAccount;
  let loggedUser;
  var userData;
  let isSameAdminUser = false;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }

  if (location.pathname === "/profile/edit") {
    userData = location.state.userData;
  } else {
    //GET USER DATA FROM LOGGED IN ACCOUNT
    loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    userData = {
      ...loggedUser,
      email: activeAccount.username,
    };
    isSameAdminUser = true
  }
  //HANDLE TAB CHANGE
  const onChange = (key) => {
    if (!isSaveDisabled) {
      showConfirm(key);
      return;
    } else {
      setActiveTab(key);
    }
  };

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to proceed without saving changes?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        setIsSaveDisabled(true);
        setDefaultTab(key);
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };
  //TABS
  const items = [
    {
      key: 1,
      label: "Event Information",
      children: (
        <EventInformation setActiveTab={setActiveTab}/>
      ),
    },
    {
      key: 2,
      label: "Facilitator",
      children: (
        <Facilitator setActiveTab={setActiveTab}/>
      ),
    },
    {
      key: 3,
      label: "Schedule",
      children: (
        <Schedule setActiveTab={setActiveTab}/>
      ),
    },
    {
      key: 4,
      label: "Participants",
      children: (
        <Participants setActiveTab={setActiveTab}/>
      ),
    },
    {
      key: 5,
      label: "Notes",
      children: (
        <Notes setActiveTab={setActiveTab}/>
      ),
    },
    {
      key: 6,
      label: "Event Status",
      children: (
        <EventStatus setActiveTab={setActiveTab}/>
      ),
    },
  ];
  return (
    <>
      <Header className="profileMenuBar">
        <Flex justify="flex-start">
          <Button type="text" className="myProfileSubHeader">
            Classes And Events
          </Button>
        </Flex>
      </Header>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={activeTab}
        defaultActiveKey={defaultTab}
      ></Tabs>
    </>
  );
}

export default ProfileTabs;
