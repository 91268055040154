import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Select,
  Form,
  Input,
  Row,
  Spin,
  message,
  DatePicker,
} from "antd";
import EventService from "../../../services/EventService";
import NoteService from "../../../services/NoteService";
import CreateNote from "../../../components/ClassesEvents/CreateNote";
import FetchClient from "../../../serviceClients/FetchClient";
import CompleteEventModal from "../../../components/ClassesEvents/CompleteEventModal";

function EventStatus({ setActiveTab }) {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [onOpenCompleteModal, setOnOpenCompleteModal] = useState(false);
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [error, setError] = useState({});
  const [note, setNote] = useState({});
  const [selectedAction, setSelectedAction] = useState({});
  const [updateValues, setUpdateValues] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(location.state?.event);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const actionOptions = [
    { id: 1, label: "Cancel", value: "Cancel" },
    { id: 2, label: "Complete", value: "Complete" },
    { id: 3, label: "Permit", value: "Permit" },
    { id: 4, label: "Reject", value: "Reject" },
    { id: 5, label: "Review", value: "Review" },
  ];

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (!selectedEvent) {
      fetchEvent();
    }
  }, []);

  async function fetchEvent() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const eventService = new EventService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const eventData = await eventService.getEvent(eventId);
      setSelectedEvent(eventData[0]);
      form.setFieldsValue(eventData[0]);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const onCloseNoteCreateModal = () => {
    setNote({});
    setCreateNoteModal(false);
  };

  const onDateInputChange = () => {};

  const onEventTypeChange = (value) => {
    setSelectedAction(value);
  };

  const onCreateNote = async () => {
    setIsLoading(true);
    setNote({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
      createdNote = await _noteService.createNote({
        ...note,
        eventId: eventId,
        createdBy: `${loggedUser.firstName} ${loggedUser.lastName}`,
        status: "New Application",
      });
      messageApi.open({
        type: "success",
        content: "Note created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseNoteCreateModal();
      updateEvent()
      setIsLoading(false);
    }
  };

  const handleCreateNoteInputChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  const onFinish = async (values) => {
    console.log("values", values);
    setUpdateValues(values);
    if (values.action === "Complete") {
      setOnOpenCompleteModal(true);
    } else if (values.action === "Permit" || values.action === "Reject" || values.action === "Review") {
      setCreateNoteModal(true)
    }
  };
  const updateEvent = async () => {
    setIsLoading(true);

    // Call Service to update event data
    const _fetchClient = new FetchClient(activeAccount.idToken);
    const _eventService = new EventService(_fetchClient);

    try {
      let res;
      const eventId = localStorage.getItem("eventId");
      let updateObject = {
        id: eventId,
      };
      if ((updateValues.action = "Complete")) {
        updateObject.status = "Complete";
        updateObject.completionDate = moment(updateValues?.date).format("L");
      }
      if ((updateValues.action = "Cancel")) {
        updateObject.status = "Cancel";
        updateObject.cancellationDate = moment(updateValues?.date).format("L");
      }
      if ((updateValues.action = "Reject")) {
        updateObject.status = "Reject";
        updateObject.rejectionDate = moment(updateValues?.date).format("L");
      }
      if ((updateValues.action = "Permit")) {
        updateObject.status = "Permitted";
        updateObject.permitDate = moment(updateValues?.date).format("L");
      }
      if ((updateValues.action = "Review")) {
        updateObject.status = "Review";
        // updateObject.completionDate = moment(updateValues?.date).format("L");
      }

      res = await _eventService.updateEvent(updateObject);
      messageApi.open({
        type: "success",
        content: "Event profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } catch (error) {
      console.log(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      navigate("/classesAndEventsList", { state: { } });
    }
  };

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <CompleteEventModal
        updateEvent={updateEvent}
        onOpen={onOpenCompleteModal}
        onCancel={() => setOnOpenCompleteModal(false)}
        setOnOpenCompleteModal={setOnOpenCompleteModal}
      />
      <CreateNote
        onOpenCreateNoteModal={createNoteModal}
        onCancelCreateNoteModal={onCloseNoteCreateModal}
        onCreateNote={onCreateNote}
        note={note}
        onInputChange={handleCreateNoteInputChange}
      />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="accountDetailsForm"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={6}>
            <span style={{ fontFamily: "Arial", fontSize: 14 }}>
              Current Status
            </span>
          </Col>
        </Row>
        <Row style={{ marginTop: "10px", marginBottom: "20px" }} gutter={24}>
          <Col span={6}>
            <span
              style={{ fontFamily: "Arial", fontSize: 14, fontWeight: 700 }}
            >
              {selectedEvent?.status}
            </span>
          </Col>
          <Col span={18}>
            <span style={{ fontFamily: "Arial", fontSize: 14 }}>
              Please enter a date to update and select an Action to update
              status of event
            </span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="applicationDate" label="Application Date">
              <Input
                name="applicationDate"
                className="userListSearchBox"
                disabled
                style={{ width: "100%" }}
                onChange={onDateInputChange}
                defaultValue={
                  moment(new Date(selectedEvent?.creationDate))
                    .format("L")
                }
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="date" label="Date">
              <DatePicker
                name="date"
                className="userListSearchBox"
                style={{ width: "100%" }}
                onChange={onDateInputChange}
              ></DatePicker>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="action" label="Action">
              <Select
                name="eventType"
                className="userListSearchBox"
                onChange={onEventTypeChange}
                options={actionOptions}
                allowClear
                value={selectedAction}
                defaultValue={selectedEvent?.status}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item name="permitDate" label="Permit Date">
              <Input
                name="permitDate"
                className="userListSearchBox"
                style={{ width: "100%" }}
                onChange={onDateInputChange}
                disabled
                defaultValue={selectedEvent?.permitDate}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="rejectionDate" label="Rejection Date">
              <Input
                name="rejectionDate"
                className="userListSearchBox"
                style={{ width: "100%" }}
                onChange={onDateInputChange}
                disabled
                defaultValue={selectedEvent?.rejectionDate}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="cancellationDate" label="Cancellation Date">
              <Input
                name="cancellationDate"
                className="userListSearchBox"
                style={{ width: "100%" }}
                onChange={onDateInputChange}
                disabled
                defaultValue={selectedEvent?.cancellationDate}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="completionDate" label="Completion Date">
              <Input
                name="completionDate"
                className="userListSearchBox"
                style={{ width: "100%" }}
                onChange={onDateInputChange}
                disabled
                defaultValue={selectedEvent?.completionDate}
              ></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button htmlType="submit" className="confirmButton">
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default EventStatus;
