import { MsalProvider } from "@azure/msal-react";

import React, { useEffect } from "react";
import { Flex, Layout } from "antd";
import Sidebar from "./components/Sidebar";

import CustomHeader from "./components/CustomHeader";
import CustomSubHeader from "./components/CustomSubHeader";
import LandingComponent from "./components/LandingComponent";
import { Footer } from "antd/es/layout/layout";

import "./App.css";
import FetchClient from "./serviceClients/FetchClient";
import UserService from "./services/UserService";

const { Sider, Header, Content } = Layout;
function App({ instance }) {
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (activeAccount != null) {
      //GET DATA FOR LOGGED IN USER
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);

      async function getLoggedUser(email) {
        try {
          var loggedUser = await _userService.getUserByEmail(
            activeAccount.idTokenClaims.emails[0]
          );
          if (loggedUser) {
            sessionStorage.setItem("loggedUser", JSON.stringify(loggedUser));
          }
        } catch (error) {
          console.log(error);
        }
      }

      getLoggedUser();
    }
  });

  return (
    <MsalProvider instance={instance}>
      <Layout>
        <Header className="header">
          <CustomHeader />
        </Header>
        <Header className="subheader">
          <CustomSubHeader />
        </Header>
        <Layout>
          <Sider width={250} theme="light" trigger={null} className="sider">
            <Sidebar parentActiveAccount={activeAccount} />
          </Sider>
          <Content className="content">
            <Flex gap="large">
              <LandingComponent />
            </Flex>
          </Content>
        </Layout>
        <Footer className="footer">Footer</Footer>
      </Layout>
    </MsalProvider>
  );
}

export default App;
