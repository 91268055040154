import { Button, Flex, Breadcrumb } from "antd";
import { React, useEffect, useState } from "react";
import { ProfileHeaderOptions } from "./HeaderOptions";
import { useMsal } from "@azure/msal-react";

function ProfileHeader({ userData }) {
  const { instance } = useMsal();
  const [userName, setUserName] = useState();
  const [isMyProfile, setIsMyProfile] = useState(null);

  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (instance) {
      var profileTitle = "";
      var activeAccount = instance.getActiveAccount();
      if (activeAccount != null) {
        if (userData.email === activeAccount.username) {
          setIsMyProfile(true);
          profileTitle = "My Profile";
        } else {
          setIsMyProfile(false);
          //GET FULL NAME
          profileTitle = userData.firstName + " " + userData.lastName;
        }
        setUserName(profileTitle);
      }
    }
  }, [
    activeAccount,
    instance,
    userData.email,
    userData.firstName,
    userData.lastName,
  ]);
  return (
    <>
      <Flex align="flex-start" justify="space-between">
        <Flex justify="flex-start">
          {isMyProfile && (
            <Button type="text" className="myProfileSubHeader">
              My Profile
            </Button>
          )}
          {!isMyProfile && (
            <Breadcrumb
              separator=">"
              items={[
                { title: "Users", href: "/userList" },
                { title: userName },
              ]}
            />
          )}
        </Flex>
        <Flex justify="flex-end">
          {ProfileHeaderOptions.map((item, index) => (
            <Button key={index} className="myProfileMenuItems" type="link">
              {item.title}
            </Button>
          ))}
        </Flex>
      </Flex>
    </>
  );
}

export default ProfileHeader;
