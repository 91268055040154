class FetchClient {
  constructor(idToken) {
    this.idToken = idToken;
  }

  async get(url) {
    return await fetch(url, {
      headers: {
        Authorization: "Bearer " + this.idToken,
      },
    });
  }

  async post(url, body) {
    return await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.idToken,
      },
    });
  }
}

export default FetchClient;
