import { Flex, Breadcrumb } from "antd";
import { React } from "react";

function CustomHeader({items}) {
  return (
    <>
      <Flex align="flex-start" justify="space-between">
        <Flex justify="flex-start">
          <Breadcrumb
            separator=">"
            items={items}
          />
        </Flex>
      </Flex>
    </>
  );
}

export default CustomHeader;
