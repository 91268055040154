import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { countryOptions } from "../../shared/selectOptions";
import { useNavigate } from "react-router-dom";
import { State, Country } from "country-state-list";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import { useMsal } from "@azure/msal-react";

function ShippingDetails({
  userData,
  isSaveDisabled,
  setIsSaveDisabled,
  loggedUser,
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [shippingStateDisabled, setShippingStateDisabled] = useState(true);
  const [activeAccount, setActiveAccount] = useState({});
  const [billingStateDisabled, setBillingStateDisabled] = useState(true);
  const [countries, setCountries] = useState([]);
  const [shippingStateList, setShippingStateList] = useState([]);
  const [billingStateList, setBillingStateList] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const onHandleIsSameAddressCheck = (e) => {
    setIsSaveDisabled(false);
    if (e.target.checked) {
      const addressValues = form.getFieldsValue();
      if (addressValues.shippingCountry.toLowerCase() === "usa") {
        var states = State.getStatesOfCountry("US");
        setBillingStateList(states);
      } else if (addressValues.shippingCountry.toLowerCase() === "can") {
        var states = State.getStatesOfCountry("CA");
        setBillingStateList(states);
      }

      //Copy Values to Billing Address
      form.setFieldsValue({
        billingCountry: addressValues.shippingCountry,
        billingAddress1: addressValues.shippingAddress1,
        billingAddress2: addressValues.shippingAddress2,
        billingCity: addressValues.shippingCity,
        billingState: addressValues.shippingState,
        billingZipCode: addressValues.shippingZipCode,
      });
    } else {
      //Reset values from Billing Address
      form.setFieldsValue({
        billingCountry: "",
        billingAddress1: "",
        billingAddress2: "",
        billingCity: "",
        billingState: "",
        billingZipCode: "",
      });
      setBillingStateList([]);
    }
  };

  const onShippingCountryChange = (value) => {
    setIsSaveDisabled(false);
    if (value.toLowerCase() === "usa") {
      setShippingStateDisabled(false)
      let states = State.getStatesOfCountry("US");
      setShippingStateList(states);
    } else if (value.toLowerCase() === "can") {
      setShippingStateDisabled(false)
      let states = State.getStatesOfCountry("CA");
      setShippingStateList(states);
      } else {
      setShippingStateDisabled(true)
      setBillingStateList([]);
    }
  };

  const onBillingCountryChange = (value) => {
    setIsSaveDisabled(false);
    if (value.toLowerCase() === "usa") {
      setBillingStateDisabled(false)
      let states = State.getStatesOfCountry("US");
      setBillingStateList(states);
    } else if (value.toLowerCase() === "can") {
      setBillingStateDisabled(false)
      let states = State.getStatesOfCountry("CA");
      setBillingStateList(states);
      } else {
      setBillingStateDisabled(true)
      setBillingStateList([]);
    }
  };


  const onShippingStateChange = (value) => {
    setIsSaveDisabled(false);
  };

  const onShippingStateSearch = (value) => {
    console.log(value);
  };

  const shippingStateFilter = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onBillingStateChange = (value) => {
    setIsSaveDisabled(false);
    console.log(value);
  };

  const onBillingStateSearch = (value) => {
    console.log(value);
  };

  const billingStateFilter = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleFormChange = () => {
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    console.log(values);
    setIsLoading(true);
    //Call Service to update user data
    //CREATE USER OBJECT
    const updatedUser = {
      email: userData.email,
      shippingCountry: values.shippingCountry,
      shippingAddress1: values.shippingAddress1,
      shippingAddress2: values.shippingAddress2,
      shippingCity: values.shippingCity,
      shippingState: values.shippingState,
      shippingZipCode: values.shippingZipCode,
      isMyBillingAddress: values.isMyBillingAddress,
      billingCountry: values.billingCountry,
      billingAddress1: values.billingAddress1,
      billingAddress2: values.billingAddress2,
      billingCity: values.billingCity,
      billingState: values.billingState,
      billingZipCode: values.billingZipCode,
    };

    const _fetchClient = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const _userService = new UserService(_fetchClient);

    try {
      //CALL USER SERVICE API
      let res = await _userService.updateUser(updatedUser);
      navigate(".", { state: { userData: res } }); 

      //SHOW SUCCESS NOTIFICATION MESSAGE
      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsSaveDisabled(true);
      setIsLoading(false);
    } catch (error) {
      //LOG ERROR
      console.log(error);

      //SHOW ERROR NOTIFICATION MESSAGE
      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (instance) {
      setActiveAccount(instance.getActiveAccount())
    }
    if (countries != []) {
      setCountries(Country.getAllCountries().map(country => {
        return country.isoCode == 'US' ? {
          label: country.name,
          value: 'usa'
        } : country.isoCode == 'CA' ? {
          label: country.name,
          value: 'can'
        } : {
          label: country.name,
          value: country.isoCode
        }
        
      }))
    }
    //SET SHIPPING STATE DROPDOWN
    if (userData.shippingCountry?.toLowerCase() === "usa") {
      let states = State.getStatesOfCountry("US");
      setShippingStateList(states);
    } else if (userData.shippingCountry?.toLowerCase() === "can") {
      let states = State.getStatesOfCountry("CA");
      setShippingStateList(states);
    } else {
      setShippingStateList([]);
    }

    //SET BILLING STATE DROPDOWN
    if (userData.billingCountry?.toLowerCase() === "usa") {
      let states = State.getStatesOfCountry("US");
      setBillingStateList(states);
    } else if (userData.billingCountry?.toLowerCase() === "can") {
      let states = State.getStatesOfCountry("CA");
      setBillingStateList(states);
    } else {
      setBillingStateList([]);
    }
  }, []);

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        size="large"
        form={form}
        name="addressesForm"
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          shippingCountry: userData.shippingCountry,
          shippingAddress1: userData.shippingAddress1,
          shippingAddress2: userData.shippingAddress2,
          shippingCity: userData.shippingCity,
          shippingState: userData.shippingState,
          shippingZipCode: userData.shippingZipCode,
          billingCountry: userData.billingCountry,
          billingAddress1: userData.billingAddress1,
          billingAddress2: userData.billingAddress2,
          billingCity: userData.billingCity,
          billingState: userData.billingState,
          billingZipCode: userData.billingZipCode,
        }}
      >
        <Row gutter={24}>
          <Col span={12}>
            <span className="addressProfileTitle">Shipping Address</span>
          </Col>
          <Col span={12}>
            <span className="addressProfileTitle">Billing Address</span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Country" name="shippingCountry">
              <Select
                label="Country"
                showSearch
                options={countries}
                onChange={onShippingCountryChange}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Country" name="billingCountry">
              <Select
                label="Country"
                showSearch
                options={countries}
                onChange={onBillingCountryChange}
              ></Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address 1" name="shippingAddress1">
              <Input
                name="shippingAddress1"
                onChange={handleFormChange}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address 1" name="billingAddress1">
              <Input name="billingAddress1" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Address 2" name="shippingAddress2">
              <Input
                name="shippingAddress2"
                onChange={handleFormChange}
              ></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Address 2" name="billingAddress2">
              <Input name="billingAddress2" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="City" name="shippingCity">
              <Input name="shippingCity" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="City" name="billingCity">
              <Input name="billingCity" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
        </Row>
        {(!shippingStateDisabled || !billingStateDisabled) &&
          <Row gutter={24}>
            <Col span={12}>
              {!shippingStateDisabled && 
                <Form.Item label="State" name="shippingState">
                  <Select
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    onChange={onShippingStateChange}
                    onSearch={onShippingStateSearch}
                    filterOption={shippingStateFilter}
                    options={(shippingStateList || []).map((s) => ({
                      value: s.isoCode,
                      label: s.name,
                    }))}
                  />
                </Form.Item>
              }
            </Col>
            <Col span={12}>
              {!billingStateDisabled && 
                <Form.Item label="State" name="billingState">
                  <Select
                    showSearch
                    placeholder="Select State"
                    optionFilterProp="children"
                    onChange={onBillingStateChange}
                    onSearch={onBillingStateSearch}
                    filterOption={billingStateFilter}
                    options={(billingStateList || []).map((s) => ({
                      value: s.isoCode,
                      label: s.name,
                    }))}
                  />
                </Form.Item>
              }
            </Col>
          </Row>
        }
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="ZIP Code" name="shippingZipCode">
              <Input name="shippingZipCode" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="ZIP Code" name="billingZipCode">
              <Input name="billingZipCode" onChange={handleFormChange}></Input>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ textAlign: "right" }}>
            <Form.Item name="isMyBillingAddress" valuePropName="checked">
              <Checkbox onChange={onHandleIsSameAddressCheck}>
                This is my billing address
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Form.Item>
              <Button
                htmlType="submit"
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default ShippingDetails;
