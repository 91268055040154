import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import moment from "moment";
import { ExclamationCircleFilled } from "@ant-design/icons";
import NoteService from "../../../services/NoteService";
import FetchClient from "../../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Space,
  Table,
  Button,
  message,
  Row,
  Col,
} from "antd";
import { PencilSquare } from "react-bootstrap-icons";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import CreateNote from "../../../components/ClassesEvents/CreateNote";

function Notes({ setActiveTab }) {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const [messageApi, contextHolder] = message.useMessage();
  const [note, setNote] = useState({});
  const [notes, setNotes] = useState([]);
  const [allNotes, setAllNotes] = useState([]);
  const [editNote, setEditNote] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createNoteModal, setCreateNoteModal] = useState(false);
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [50, 100, 200],
    },
  });
  const [selectedNotes, setSelectedNotes] = useState([]);
  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET COURSE LIST
  async function fetchNotes() {
    // setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE COURSE SERVICE INSTANCE
    const noteService = new NoteService(_fetchInstance);

    try {
      let eventId = localStorage.getItem("eventId");
      const noteData = await noteService.getAllNotes(eventId);
      setNotes(noteData);
      setAllNotes(noteData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, total: noteData.length },
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  function showDeleteModal() {
    showConfirm();
  }

  async function deleteNote() {
    setIsLoading(true);
    setSelectedNotes([]);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);

    try {
      //DELETE USER
      await _noteService.deleteNote(selectedNotes[0]);
      messageApi.open({
        type: "success",
        content: "Note deleted successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
      fetchNotes();
    }
  }

  useEffect(() => {
    fetchNotes();
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setNotes([]);
    }
  };

  //HANDLE EDIT COURSE BUTTON CLICK
  const handleEditClick = (record) => {
    setEditNote(record);
    setCreateNoteModal(true);
  };

  //SET COURSE LIST TABLE COLUMNS
  const columns = [
    {
      title: "Note Item",
      dataIndex: "note",
      key: "note",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.note?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.note.localeCompare(b.note),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.status?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.createdBy?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Date Created",
      dataIndex: "creationDate",
      key: "creationDate",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return record.creationDate?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.creationDate.localeCompare(b.creationDate),
    },
    {
      title: "",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleEditClick(record);
            }}
          />
        </Space>
      ),
    },
  ];

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to delete this note?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        deleteNote();
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };

  const onCloseNoteCreateModal = () => {
    setEditNote({});
    setNote({});
    setCreateNoteModal(false);
  };

  const onCreateNote = async () => {
    setIsLoading(true);
    setEditNote({});
    setNote({});
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      //CREATE USER
      let eventId = localStorage.getItem("eventId");
      let loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
      createdNote = await _noteService.createNote({
        ...note,
        eventId: eventId,
        createdBy: `${loggedUser.firstName} ${loggedUser.lastName}`,
        status: "New Application",
      });
      messageApi.open({
        type: "success",
        content: "Note created successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseNoteCreateModal();
      setIsLoading(false);
      fetchNotes();
    }
  };
  const onEditNote = async () => {
    setIsLoading(true);
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    const _noteService = new NoteService(_fetchInstance);
    let createdNote = {};

    try {
      //CREATE USER
      createdNote = await _noteService.updateNote(note);
      messageApi.open({
        type: "success",
        content: "Note updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
      setIsLoading(false);
    } catch (error) {
      console.error(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      onCloseNoteCreateModal();
      setIsLoading(false);
      fetchNotes();
    }
  };
  const handleCreateNoteInputChange = (e) => {
    const { name, value } = e.target;
    setNote({ ...note, [name]: value });
  };

  //HANDLE COURSE LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedNotes(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled Note",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex vertical align="flex-start">
            <div className="course-table">
              <Space
                direction="vertical"
                size="small"
                style={{ display: "flex" }}
              >
                <Space size="small" align="center">
                  <Input.Search
                    placeholder="Name"
                    className="courseListSearchBox"
                    onSearch={(value) => {
                      setNameSearchText(value);
                    }}
                    onChange={(e) => {
                      setNameSearchText(e.target.value);
                    }}
                    style={{ width: "200px" }}
                    value={nameSearchText}
                  />
                  {selectedNotes.length === 1 && (
                    <Button
                      onClick={showDeleteModal}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    onClick={() => setCreateNoteModal(true)}
                    className="confirmButton"
                    style={{ marginBottom: "8px" }}
                  >
                    Add Note Item +
                  </Button>
                </Space>
              </Space>
              <Table
                rowClassName={"tableRowActiveNote"}
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns={columns}
                dataSource={notes}
                rowKey={(record) => record.id}
                pagination={tableParams.pagination}
                onChange={handleTableChange}
              />
              <CreateNote
                onOpenCreateNoteModal={createNoteModal}
                onCancelCreateNoteModal={onCloseNoteCreateModal}
                onEditNote={onEditNote}
                onCreateNote={onCreateNote}
                onInputChange={handleCreateNoteInputChange}
                setNote={setNote}
                note={note}
                allNotes={notes}
                editNote={editNote}
              />
            </div>
          </Flex>
        </Flex>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => setActiveTab(6)}
                htmlType="submit"
                className="confirmButton"
              >
                Next
              </Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
}
export default Notes;
