import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import dayjs from "dayjs";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";

function AccountDetails({
  userData,
  isSaveDisabled,
  setIsSaveDisabled,
  loggedUser,
  isSameAdminUser
}) {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailAddressEditable, setIsEmailAddressEditable] = useState(true);
  const [membershipLevelEnabled, setMembershipLevelEnabled] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const [passwordEnabled, setPasswordEnabled] = useState(false);
  const [email, setEmail] = useState(userData.email ? userData.email : null);
  const [prospectDate, setProspectDate] = useState(
    userData.prospectDate ? dayjs(userData.prospectDate) : null
  );
  const [beamerDate, setBeamerDate] = useState(
    userData.beamerDate ? dayjs(userData.beamerDate) : null
  );
  const [pathSeekerDate, setPathSeekerDate] = useState(
    userData.pathSeekerDate ? dayjs(userData.pathSeekerDate) : null
  );
  const [teacherDate, setTeacherDate] = useState(
    userData.teacherDate ? dayjs(userData.teacherDate) : null
  );

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  useEffect(() => {
    if (instance) {
      var activeAccount = instance.getActiveAccount();
      if (activeAccount != null) {
        if (userData.email === activeAccount.username) {
          setPasswordEnabled(true);
        } else {
          setPasswordEnabled(false);
        }
      }
      //CHECK USER ROLE PERMISSIONS
      if (isSameAdminUser) {
        setIsEmailAddressEditable(true);
        setPasswordEnabled(true);
        setMembershipLevelEnabled(false);
      } else if (loggedUser.role.toLowerCase() === "admin") {
        setIsEmailAddressEditable(true);
        setPasswordEnabled(false);
        setMembershipLevelEnabled(true);
      } else if (loggedUser.role.toLowerCase() === "user") {
        setIsEmailAddressEditable(true);
        setPasswordEnabled(true);
        setMembershipLevelEnabled(false);
      } else if (loggedUser.role.toLowerCase() === "teachingcoordinator") {
        setIsEmailAddressEditable(true);
        setPasswordEnabled(true);
        setMembershipLevelEnabled(false);
      } else if (loggedUser.role.toLowerCase() === "clubadmin") {
        setIsEmailAddressEditable(true);
        setPasswordEnabled(true);
        setMembershipLevelEnabled(false);
      } else {
        setIsEmailAddressEditable(false);
        setPasswordEnabled(false);
        setMembershipLevelEnabled(false);
      }
    }
  }, [instance, loggedUser.role, passwordEnabled, userData.email]);
  useEffect(() => {
    if (Object.keys(userData).length > 0) {
      setProspectDate(dayjs(userData.prospectDate));
      setBeamerDate(dayjs(userData.beamerDate));
      setPathSeekerDate(dayjs(userData.pathSeekerDate));
      setTeacherDate(dayjs(userData.teacherDate));
      setEmail(userData.email)
    }
  }, [userData]);

  const onChangeProspectDate = (date, dateString) => {
    setProspectDate(dayjs(dateString));
    setIsSaveDisabled(false);
  };
  const onChangeBeamerDate = (date, dateString) => {
    setBeamerDate(dayjs(dateString));
    setIsSaveDisabled(false);
  };

  const onChangePathSeekerDate = (date, dateString) => {
    setPathSeekerDate(dayjs(dateString));
    setIsSaveDisabled(false);
  };

  const onChangeTeacherDate = (date, dateString) => {
    setTeacherDate(dayjs(dateString));
    setIsSaveDisabled(false);
  };

  const handleFormChange = () => {
    setIsSaveDisabled(false);
  };

  const onFinish = async (values) => {
    console.log(values);
    setIsLoading(true);

    const updatedUser = {
      email: values.email,
      prospectDate: prospectDate,
      beamerDate: beamerDate,
      pathSeekerDate: pathSeekerDate,
      teacherDate: teacherDate,
    };

    const passwordModel = {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      confirmPassword: values.confirmPassword,
      };
      //Call Service to update user data
      const _fetchClient = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchClient);
      
      try {
        let res = await _userService.updateUser(updatedUser);
        navigate(".", { state: { userData: res } }); 

      messageApi.open({
        type: "success",
        content: "User profile updated successfully!",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });

      setIsSaveDisabled(true);
      setIsLoading(false);
    } catch (error) {
      console.log(error);

      messageApi.open({
        type: "error",
        content: "Something went wrong. Please try again later.",
        className: "custom-class",
        duration: "5",
        style: {
          marginTop: "20vh",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const [form] = Form.useForm();

  return (
    <Card style={{ padding: "20px" }} bordered={false}>
      {contextHolder}
      <Spin spinning={isLoading} fullscreen size="large" />
      <Form
        form={form}
        layout="vertical"
        size="large"
        name="accountDetailsForm"
        onFinish={onFinish}
      >
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <span className="membershipLevelsTitle">Membership Level</span>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Email Address (Username)"
              name="email"
              rules={[{ required: true, message: "Please enter your email" }]}
            >
              <Input
                onChange={handleFormChange}
                disabled
                defaultValue={email}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label=" "
              style={{ textAlign: "center" }}
              name="prospectDate"
            >
              <Space align="center" size={0}>
                <span className="prospect-block">Prospect</span>
                <DatePicker
                  onChange={onChangeProspectDate}
                  style={{ width: "300px" }}
                  value={prospectDate}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Current Password" name="currentPassword">
              <Input.Password
                name="currentPassword"
                disabled={!passwordEnabled}
                onChange={handleFormChange}
              ></Input.Password>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label=" "
              style={{ textAlign: "center" }}
              name="beamerDate"
            >
              <Space align="center" size={0}>
                <span className="beamer-block">Beamer</span>
                <DatePicker
                  value={beamerDate}
                  onChange={onChangeBeamerDate}
                  style={{ width: "300px" }}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="New Password" name="newPassword">
              <Input.Password
                name="newPassword"
                disabled={!passwordEnabled}
                onChange={handleFormChange}
              ></Input.Password>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label=" "
              style={{ textAlign: "center" }}
              name="pathSeekerDate"
            >
              <Space align="center" size={0}>
                <span className="pathSeeker-block">Path Seeker</span>
                <DatePicker
                  value={pathSeekerDate}
                  onChange={onChangePathSeekerDate}
                  style={{ width: "300px" }}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              label="Confirm New Password"
              name="confirmNewPassword"
              dependencies={["newPassword"]}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newPassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                name="confirmNewPassword"
                disabled={!passwordEnabled}
              ></Input.Password>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label=" "
              style={{ textAlign: "center" }}
              name="teacherDate"
            >
              <Space align="center" size={0}>
                <span className="teacher-block">Teacher</span>
                <DatePicker
                  value={teacherDate}
                  onChange={onChangeTeacherDate}
                  style={{ width: "300px" }}
                  disabled={!membershipLevelEnabled}
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}></Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}></Col>
          <Col span={12}>
            <div style={{ textAlign: "right" }}>
              <Button
                htmlType="submit"
                className="confirmButton"
                disabled={isSaveDisabled}
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}

export default AccountDetails;
