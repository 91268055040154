const developmentConfig = {
  BASE_URL: "http://localhost:7071/api",
};

const productionConfig = {
  BASE_URL: "https://bp-ctiserverlessprod.azurewebsites.net/api",
};

const config =
  process.env.NODE_ENV === "production" ? productionConfig : developmentConfig;

module.exports = config;
