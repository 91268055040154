const config = require("../config");

class UserService {
  constructor(httpClient) {
    this.BASE_URL = config.BASE_URL;
    this.httpClient = httpClient;
  }

  async getUsers() {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetAllUsers?"
      );

      if (!response.ok) {
        throw new Error("Failed to fetch users.");
      }

      const users = await response.json();
      return users;
    } catch (error) {
      console.error("Error fetching users: ", error);
      return [];
    }
  }

  async getUserByEmail(email) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL + "/GetUserByEmail?email=" + email
      );

      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const user = await response.json();
      return user;
    } catch (error) {
      console.error("Error fetching user: ", error);
      return null;
    }
  }

  async createUser(user) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/CreateUser",
        user
      );
      if (response.status === 409) {
        return null
      }
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUserInAD(user) {
    try {
      const response = await this.httpClient.get(
        this.BASE_URL +
          "/CreateUserInAD?email=" +
          user.email +
          "&firstName=" +
          user.firstName +
          "&lastName=" +
          user.lastName
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async batchUpdateUsers(usersActionModel) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/BatchUserUpdate",
        usersActionModel
      );

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateUser(user) {
    try {
      const response = await this.httpClient.post(
        this.BASE_URL + "/UpdateUser",
        user
      );
      return response.json();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updatePassword(passwordModel) {
    try {
      await this.httpClient.post(
        this.BASE_URL + "/UpdatePassword",
        passwordModel
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default UserService;
