export const fillTemplate = (template, data) => {
  let result = template;
  for (const key in data) {
    const placeholder = `{${key}}`;
    const value = data[key];
    result = result.replace(new RegExp(placeholder, "g"), value);
  }
  return result;
};

export const generateRandomCode = (digits) => {
  if (digits < 1) {
    throw new Error("Digits cannot be less than 1");
  }
  const min = Math.pow(10, digits - 1);
  const max = Math.pow(10, digits) - 1;
  return Math.floor(min + Math.random() * (max - min + 1)).toString();
};
