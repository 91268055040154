export const newUserEmailTemplate = `      
    <p>
        Hi {firstName} {lastName},
    </p>

    <p>Your Concept-Therapy web site account has been created. You need to verify your e-mail address and set a new password by clicking on the link below.</p>

    <p>
        <a href="{resetPasswordLink}">Click here to verify your account.</a>
    </p>

    <p>
        Regards, <br />
        Concept-Therapy
    </p>`;
