import { Button, Form, Input, Modal, Select, DatePicker, Radio } from "antd";
import UserService from "../../services/UserService";
import FetchClient from "../../serviceClients/FetchClient";
import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import "react-phone-input-2/lib/style.css";

function CreateParticipant({
  onOpenCreateParticipantModal,
  onCancelCreateParticipantModal,
  onCreateParticipant,
  onEditParticipant,
  onInputChange,
  onDateInputChange,
  setParticipant,
  editParticipant,
  participant,
  onSelectChange,
}) {
  const { instance } = useMsal();
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [users, setUsers] = useState([]);
  const [credit, setCredit] = useState("0");
  const [feePaid, setFeePaid] = useState("0");

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const cancelModal = () => {
    onCancelCreateParticipantModal();
  };

  useEffect(() => {
    setIsEditMode(editParticipant && Object.keys(editParticipant).length > 0);
  }, [editParticipant]);

  useEffect(() => {
    if (isEditMode) {
      setParticipant(editParticipant);
    }
  }, [isEditMode]);
  useEffect(() => {
    fetchUsers();
  }, []);

  async function fetchUsers() {
    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const userService = new UserService(_fetchInstance);

    try {
      const usersData = await userService.getUsers();
      setUsers(
        usersData.map((user) => {
          return {
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
          };
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
    }
  }

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Participant" : "New Participant"}
        centered
        destroyOnClose={true}
        open={onOpenCreateParticipantModal}
        onOk={isEditMode ? onEditParticipant : onCreateParticipant}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={isEditMode ? onEditParticipant : onCreateParticipant}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            {isEditMode ? "Save" : "Add Participant"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item name="userId" label="Participant" required>
            <Select
              name="userId"
              showSearch
              optionFilterProp="label"
              placeholder="Search Users"
              onChange={onSelectChange}
              options={users}
            />
          </Form.Item>
          <Form.Item name="role" label="Role" required>
            <Select
              name="role"
              placeholder="Role"
              onChange={onInputChange}
              options={[]}
            />
          </Form.Item>
          <Form.Item name="paymentDate" label="Payment Date" required>
            <DatePicker
              name="paymentDate"
              className="userListSearchBox"
              style={{ width: "100%" }}
              onChange={onDateInputChange}
            ></DatePicker>
          </Form.Item>
          <Form.Item
            label="Fee Paid"
            name="feePaid"
            valuePropName="checked"
            required
          >
            <Radio.Group
              onChange={(v) => {
                setParticipant({ ...participant, feePaid: v.target.value });
                setFeePaid(v.target.value);
              }}
              value={feePaid}
            >
              <Radio value={"1"}>Yes</Radio>
              <Radio value={"0"}>No</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Credit"
            name="credit"
            valuePropName="checked"
            required
          >
            <Radio.Group
              onChange={(v) => {
                setParticipant({ ...participant, credit: v.target.value });
                setCredit(v.target.value);
              }}
              value={credit}
            >
              <Radio value={"1"}>Yes</Radio>
              <Radio value={"0"}>No</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateParticipant;
