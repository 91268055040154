import React, { useState, useEffect } from "react";
import UserService from "../../../services/UserService";
import FetchClient from "../../../serviceClients/FetchClient";
import {
  Card,
  Flex,
  Input,
  Select,
  Space,
  Table,
  DatePicker,
  Button,
  Dropdown,
  message,
} from "antd";
import { Header } from "antd/es/layout/layout";
import CustomHeader from "../../../components/Header";
import { DownOutlined } from "@ant-design/icons";
import { PencilSquare } from "react-bootstrap-icons";
import prospectIcon from "../../../images/prospecticon.png";
import beamerIcon from "../../../images/beamericon.png";
import pathSeekerIcon from "../../../images/pathseekericon.png";
import teacherIcon from "../../../images/teachericon.png";
import { ColorRing } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {
  accountLevelOptions,
  activeInactiveUserOptions,
  activeUserOptions,
  inactiveUserOptions,
  noUserSelectedOptions,
  titleOptions,
} from "../../../shared/selectOptions";

function UserList() {
  const { instance } = useMsal();
  const { RangePicker } = DatePicker;
  const [messageApi, contextHolder] = message.useMessage();
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userActionsItems, setUserActionsItems] = useState(
    noUserSelectedOptions
  );
  const [error, setError] = useState();
  const [nameSearchText, setNameSearchText] = useState("");
  const [citySearchText, setCitySearchText] = useState("");
  const [accountLevelSerchText, setAccountLevelSearchText] = useState("");
  const [accountLevelStartDate, setAccountLevelStartDate] = useState();
  const [accountLevelEndDate, setAccountLevelEndDate] = useState();
  const [selectedAccountLvelValues, setSelectedAccountLevelValues] = useState(
    []
  );
  const [countrySearchText, setCountrySearchText] = useState("");
  const [titleSerchText, setTitleSearchText] = useState("");
  const [selectedTitleValues, setSelectedTitleValues] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 50,
      showTotal: (total, range) =>
        `Viewing ${range[0]}-${range[1]} of ${total} results`,
      pageSizeOptions: [50, 100, 200],
    },
  });
  const [selectedUsers, setSelectedUsers] = useState("");

  const navigate = useNavigate();

  let activeAccount;
  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  //GET USER LIST
  async function fetchUsers() {
    setIsLoading(true);

    const _fetchInstance = new FetchClient(activeAccount.idToken);
    //CREATE USER SERVICE INSTANCE
    const userService = new UserService(_fetchInstance);

    try {
      const usersData = await userService.getUsers();
      setUsers(usersData);
      setAllUsers(usersData);
      setTableParams({
        ...tableParams,
        pagination: { ...tableParams.pagination, total: usersData.length },
      });
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchUsers();
  }, [
    activeAccount.idToken,
    tableParams.pagination?.current,
    tableParams.pagination?.pageSize,
  ]);

  //HANDLE TABLE CHANGE
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({ pagination, filters, ...sorter });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUsers([]);
    }
  };
  // HANDLE  ACCOUNT LEVEL DATE RANGE CHANGE
  const handleAccountLevelRangeChange = (_, info) => {
    console.log(info);
    setAccountLevelStartDate(info[0]._d);
    setAccountLevelEndDate(info[1]._d);
  };

  //HANDLE EDIT USER BUTTON CLICK
  const onEditUser = (record) => {
    navigate("/profile/edit", { state: { userData: record } });
  };

  //HANDLE INACTIVE BUTTON CLICK
  const handleInactiveButtonClick = () => {
    var inactiveUsers = allUsers.filter((user) => {
      return user.isActive === "0";
    });

    setUsers(inactiveUsers);
  };

  //HANDLE ACTIVE BUTTON CLICK
  const handleActiveButtonClick = () => {
    var activeUsers = allUsers.filter((user) => {
      return user.isActive === "1";
    });

    setUsers(activeUsers);
  };

  //HANDLE ALL USERS BUTTON CLICK
  const handleAllUsersButtonClick = () => {
    setUsers(allUsers);
  };

  //HANDLE TITLE DROPDOWN CHANGE
  const handleTitleSearchChange = (value) => {
    setTitleSearchText(value);
    setSelectedTitleValues(value);
    console.log(`selected ${value}`);
  };

  //HANDLE ACCOUNT LEVEL SELECT CHANGE
  const handleAccountLevelSearchChange = (value) => {
    setAccountLevelSearchText(value);
    setSelectedAccountLevelValues(value);
    console.log(value);
  };

  //HANDLE CLEAR FILTERS CLICK
  const clearFilters = () => {
    setCitySearchText("");
    setCountrySearchText("");
    setAccountLevelSearchText("");
    setTitleSearchText("");
    setNameSearchText("");
    setSelectedTitleValues([]);
    setSelectedAccountLevelValues([]);
    setAccountLevelStartDate("");
    setAccountLevelEndDate("");
  };

  const items = userActionsItems;

  const handleMenuClick = async (e) => {
    if (
      selectedUsers !== null &&
      selectedUsers !== undefined &&
      selectedUsers !== "" &&
      selectedUsers.length > 0
    ) {
      setIsLoading(true);
      const _fetchInstance = new FetchClient(activeAccount.idToken);
      const _userService = new UserService(_fetchInstance);

      try {
        var usersActionModel = {
          users: selectedUsers,
          action: e.key.toLowerCase(),
        };

        await _userService.batchUpdateUsers(usersActionModel);

        setSelectedUsers("");

        fetchUsers();

        messageApi.open({
          type: "success",
          content: "Users updated successfully!",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });

        setIsLoading(false);
      } catch (error) {
        console.error(error);

        messageApi.open({
          type: "error",
          content: "Something went wrong. Please try again later.",
          className: "custom-class",
          duration: "5",
          style: {
            marginTop: "20vh",
          },
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  //SET USER LIST TABLE COLUMNS
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      filteredValue: [titleSerchText],
      onFilter: (value, record) => {
        if(value && value != "") {
          return value.toLowerCase().includes(record.title?.toLowerCase());
        } else {
          return true
        }
      },
      sorter: (a, b) => a.title?.localeCompare(b.title),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [nameSearchText],
      onFilter: (value, record) => {
        return (
          record.firstName?.toLowerCase().includes(value.toLowerCase()) ||
          record.lastName?.toLowerCase().includes(value.toLowerCase()) ||
          record.email?.toLowerCase().includes(value.toLowerCase())
        );
      },
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (_, record) => (
        <span>
          <div>
            {record.firstName} {record.lastName}
          </div>
          <div>{record.email}</div>
        </span>
      ),
    },
    {
      title: "Account Level",
      dataIndex: "accountLevel",
      filteredValue: [accountLevelSerchText],
      onFilter: (value, record) => {
        if (!value || value === "" || value == 'undefined') {
          return record;
        } else if (value === "teacher") {
          return record.teacherDate && record.teacherDate !== null && record.teacherDate !== "";
        } else if (value === "pathSeeker") {
          return record.pathSeekerDate && record.pathSeekerDate !== null && record.pathSeekerDate !== "";
        } else if (value === "beamer") {
          return record.beamerDate && record.beamerDate !== null && record.beamerDate !== "";
        } else if (value === "prospect") {
          return record.prospectDate && record.prospectDate !== null && record.prospectDate !== "";
        }
      },
      render: (accountLevel) =>
        accountLevel.map((level) => {
          let htmlIcons = "";
          if (level.toUpperCase() === "PROSPECT") {
            htmlIcons = htmlIcons + `<img src=${prospectIcon} alt="P"/>`;
          }
          if (level.toUpperCase() === "BEAMER") {
            htmlIcons = htmlIcons + `<img src=${beamerIcon} alt="B"/>`;
          }
          if (level.toUpperCase() === "PATHSEEKER") {
            htmlIcons = htmlIcons + `<img src=${pathSeekerIcon} alt="PS"/>`;
          }

          if (level.toUpperCase() === "TEACHER") {
            htmlIcons = htmlIcons + `<img src=${teacherIcon} alt="T"/>`;
          }
          return (
            <span
              className="accountLevelIcons"
              dangerouslySetInnerHTML={{ __html: htmlIcons }}
            ></span>
          );
        }),
      key: "accountLevel",
    },
    {
      title: "City",
      dataIndex: "shippingCity",
      key: "shippingCity",
      filteredValue: [citySearchText],
      onFilter: (value, record) => {
        return record.shippingCity?.toLowerCase().includes(value.toLowerCase());
      },
      sorter: (a, b) => a.shippingCity?.localeCompare(b.shippingCity),
    },
    {
      title: "Country",
      dataIndex: "shippingCountry",
      key: "shippingCountry",
      filteredValue: [countrySearchText],
      onFilter: (value, record) => {
        return record.shippingCountry
          ?.toLowerCase()
          .includes(value.toLowerCase());
      },
      sorter: (a, b) => a.shippingCountry?.localeCompare(b.shippingCountry),
    },
    {
      title: "Edit",
      key: "edit",
      render: (_, record) => (
        <Space size="middle">
          <PencilSquare
            style={{ cursor: "pointer" }}
            onClick={() => {
              onEditUser(record);
            }}
          />
        </Space>
      ),
    },
  ];

  function checkUserStatus(users) {
    let hasActive = false;
    let hasInactive = false;

    for (let user of users) {
      if (user.isActive === "1") {
        hasActive = true;
      } else if ((user.isActive = "0")) {
        hasInactive = true;
      }

      if (hasActive && hasInactive) {
        return "both";
      }
    }

    if (hasActive) {
      return "active";
    } else if (hasInactive) {
      return "inactive";
    } else {
      return "";
    }
  }

  //HANDLE USER LIST SELECTION
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedUsers(selectedRowKeys);
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
      //CHECK SELECTED USERS STATUS TO DETERMINE THE USER ACTION OPTIONS
      var status = checkUserStatus(selectedRows);

      if (status.toLowerCase() === "active") {
        //SET ACTIVE USER ACTION OPTIONS
        setUserActionsItems(activeUserOptions);
      } else if (status.toLowerCase() === "inactive") {
        //SET INACTIVE USER ACTION OPTIONS
        setUserActionsItems(inactiveUserOptions);
      } else if (status.toLowerCase() === "both") {
        //SET BOTH STATUS USER ACTION OPTIONS
        setUserActionsItems(activeInactiveUserOptions);
      } else {
        //SET NO USER SELECTED ACTION OPTIONS
        setUserActionsItems(noUserSelectedOptions);
      }

      console.log("items: ", userActionsItems);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  if (isLoading) {
    return (
      <Card style={{ padding: "20px" }}>
        {contextHolder}
        <Flex vertical gap="30px">
          <Flex justify="center" vertical align="center">
            <ColorRing
              visible={true}
              height="80"
              width="80"
              ariaLabel="color-ring-loading"
              wrapperStyle={{}}
              wrapperClass="color-ring-wrapper"
              colors={["#26225A", "#66618D", "#545A6D", "#BCBEC0"]}
            />
          </Flex>
        </Flex>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        {contextHolder}
        <Flex>
          <Flex>
            <div className="centered text-4xl h-screen">
              Something went wrong! Please try again.
            </div>
          </Flex>
        </Flex>
      </Card>
    );
  }

  return (
    <>
      <Header className="profileMenuBar">
        <CustomHeader
          items={[
            { title: "User List", href: "/userList" },
          ]}
        />
      </Header>
    <Card style={{ padding: "20px" }}>
      {contextHolder}
      <Flex vertical gap="30px">
        <Flex vertical align="flex-start">
          <div className="user-table">
            <Space
              direction="vertical"
              size="small"
              style={{ display: "flex" }}
            >
              <Flex justify="space-between" align="flex-start">
                <Flex gap="small" justify="flex-start" align="center">
                  <Space size="small" align="center">
                    <span>Filter Users:</span>
                    <Button
                      className="confirmButton"
                      onClick={handleActiveButtonClick}
                    >
                      Active
                    </Button>
                    <Button
                      className="confirmFilterUsersInactive"
                      onClick={handleInactiveButtonClick}
                    >
                      Inactive
                    </Button>
                    <Button
                      className="confirmFilterUsersAll"
                      onClick={handleAllUsersButtonClick}
                    >
                      All
                    </Button>
                  </Space>
                </Flex>
                <Flex gap="small" justify="flex-end" align="center">
                  <Space size="small" align="center">
                    <Button
                      onClick={clearFilters}
                      className="confirmButton"
                      style={{ marginBottom: "8px" }}
                    >
                      Clear Filters
                    </Button>
                    <Dropdown menu={menuProps}>
                      <Button className="confirmUserActionsButton">
                        <Space>
                          User Actions <DownOutlined />
                        </Space>
                      </Button>
                    </Dropdown>
                  </Space>
                </Flex>
              </Flex>
              <Space size="small" align="center">
                <Select
                  mode="multiple"
                  placeholder="Title"
                  className="userListSearchBox"
                  onChange={handleTitleSearchChange}
                  options={titleOptions}
                  allowClear
                  style={{ width: "100px" }}
                  value={selectedTitleValues}
                ></Select>
                <Input.Search
                  placeholder="Name"
                  className="userListSearchBox"
                  onSearch={(value) => {
                    setNameSearchText(value);
                  }}
                  onChange={(e) => {
                    setNameSearchText(e.target.value);
                  }}
                  style={{ width: "200px" }}
                  value={nameSearchText}
                />
                <Select
                  placeholder="Account Level"
                  className="userListSearchBox"
                  onChange={handleAccountLevelSearchChange}
                  options={accountLevelOptions}
                  allowClear
                  style={{ width: "200px" }}
                  value={selectedAccountLvelValues}
                ></Select>
                <RangePicker
                  className="userListSearchBox"
                  onChange={handleAccountLevelRangeChange}
                ></RangePicker>
                <Input.Search
                  placeholder="City"
                  className="userListSearchBox"
                  onSearch={(value) => {
                    setCitySearchText(value);
                  }}
                  onChange={(e) => {
                    setCitySearchText(e.target.value);
                  }}
                  value={citySearchText}
                />
                <Input.Search
                  placeholder="Country"
                  className="userListSearchBox"
                  onSearch={(value) => {
                    setCountrySearchText(value);
                  }}
                  onChange={(e) => {
                    setCountrySearchText(e.target.value);
                  }}
                  value={countrySearchText}
                />
              </Space>
            </Space>
            <Table
              rowClassName={(record, index) =>
                record.isActive === "1"
                  ? "tableRowActiveUser"
                  : "tableRowInactiveUser"
              }
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              columns={columns}
              dataSource={users}
              rowKey={(record) => record.email}
              pagination={tableParams.pagination}
              onChange={handleTableChange}
            />
          </div>
        </Flex>
      </Flex>
    </Card>
    </>
  );
}
export default UserList;
