import { Button, Form, Input, Modal, Select, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const { TextArea } = Input;
const { RangePicker } = DatePicker;

function CreateSchedule({
  onOpenCreateScheduleModal,
  onCancelCreateScheduleModal,
  onCreateSchedule,
  onEditSchedule,
  onInputChange,
  onDateInputChange,
  setSchedule,
  editSchedule,
  schedule,
}) {
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);

  const cancelModal = () => {
    onCancelCreateScheduleModal();
  };

  useEffect(() => {
    setIsEditMode(editSchedule && Object.keys(editSchedule).length > 0);
  }, [editSchedule]);

  useEffect(() => {
    if (isEditMode) {
      setSchedule(editSchedule);
    }
  }, [isEditMode]);

  return (
    <div className="App">
      <Modal
        style={{ color: "#26225A" }}
        title={isEditMode ? "Edit Schedule" : "New Schedule"}
        centered
        destroyOnClose={true}
        open={onOpenCreateScheduleModal}
        onOk={isEditMode ? onEditSchedule : onCreateSchedule}
        onCancel={() => cancelModal()}
        footer={[
          <Button
            key="cancel"
            type="primary"
            style={{ background: "#26225A", borderColor: "white" }}
            onClick={() => cancelModal()}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            onClick={isEditMode ? onEditSchedule : onCreateSchedule}
            ghost
            style={{ borderColor: "#26225A", color: "#26225A" }}
          >
            {isEditMode ? "Save" : "Add Schedule"}
          </Button>,
        ]}
      >
        <Form form={form} layout="vertical">
          <Form.Item label="Schedule Item" required>
            <TextArea
              style={{ height: "80px" }}
              name="item"
              placeholder="Schedule Item"
              onChange={onInputChange}
              value={schedule && schedule.item ? schedule.item : ""}
            />
          </Form.Item>
          <Form.Item label="Start and End Time" required>
            <RangePicker
              showTime
              name="time"
              className="userListSearchBox"
              style={{width: '100%'}}
              onChange={onDateInputChange}
            ></RangePicker>
          </Form.Item>
          <Form.Item label="Presenters" required>
            <Input
              name="presenter"
              placeholder="Presenters"
              onChange={onInputChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default CreateSchedule;
