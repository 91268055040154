import { Avatar, Button, Dropdown, Flex, Menu } from "antd";
import React, { useEffect, useState } from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import ImgLogo from "../images/logo.webp";
import { RxAvatar } from "react-icons/rx";
import { IoMdLogIn, IoMdLogOut } from "react-icons/io";
import { PersonFillGear } from "react-bootstrap-icons";
import { FaGear } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function CustomHeader() {
  const [userInitials, setUserInitials] = useState("");
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  let activeAccount;

  if (instance) {
    activeAccount = instance.getActiveAccount();
  }

  const handleMyAccountRedirect = () => {
    navigate("/myProfile");
  };

  const handleAdminRedirect = () => {
    navigate("/userList");
  };

  const handleLoginRedirect = () => {
    instance.loginRedirect();
  };

  const handleLogoutRedirect = () => {
    instance.logoutRedirect();
  };

  function WidgetLogoutMenu(props) {
    return (
      <Menu {...props}>
        <Menu.Item onClick={handleMyAccountRedirect}>
          <PersonFillGear className="icon" />
          My Account
        </Menu.Item>
        <Menu.Item onClick={handleAdminRedirect}>
          <FaGear className="icon" />
          Admin
        </Menu.Item>
        <Menu.Item onClick={handleLogoutRedirect}>
          <IoMdLogOut className="icon" />
          Logout
        </Menu.Item>
      </Menu>
    );
  }

  function WidgetLoginMenu(props) {
    return (
      <Menu {...props}>
        <Menu.Item onClick={handleLoginRedirect}>
          <IoMdLogIn className="icon" />
          Login
        </Menu.Item>
      </Menu>
    );
  }

  //GET USER INITIALS FROM REAL LOGGED IN USER
  useEffect(() => {
    if (activeAccount != null) {
      var initials = "";
      //GET INITIALS
      if (activeAccount.idTokenClaims.given_name != null) {
        initials =
          activeAccount.idTokenClaims.given_name.charAt(0) +
          activeAccount.idTokenClaims.family_name.charAt(0);
      } else {
        var nameArray = activeAccount.idTokenClaims.name.split(" ");
        initials = nameArray[0].charAt(0) + nameArray[1].charAt(0);
      }
      setUserInitials(initials);
    }
  }, [activeAccount]);

  return (
    <Flex align="center" justify="space-between">
      <Flex gap="middle" align="center">
        <img src={ImgLogo} alt="Concept-Therapy" className="logo" />
      </Flex>
      <Flex align="center" gap="3rem">
        <Flex align="center" gap="10px">
          {isAuthenticated && (
            <Dropdown overlay={<WidgetLogoutMenu />}>
              <Avatar
                style={{
                  backgroundColor: "#26225A",
                  color: "#BCBEC0",
                  cursor: "pointer",
                }}
              >
                {userInitials}
              </Avatar>
            </Dropdown>
          )}
          {!isAuthenticated && (
            <Button onClick={handleLoginRedirect} className="confirmButton">
              LOGIN
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default CustomHeader;
