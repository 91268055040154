import { Tabs, Modal } from "antd";
import React, { useState } from "react";
import AccountDetails from "./AccountDetails";
import PersonalInformation from "./PersonalInformation";
import Membership from "./Membership";
import ShippingDetails from "./ShippingDetails";
import { MdAccountCircle, MdCardMembership } from "react-icons/md";
import { CgDetailsMore } from "react-icons/cg";
import { useLocation } from "react-router-dom";
import { ImAddressBook } from "react-icons/im";
import { useMsal } from "@azure/msal-react";
import { Header } from "antd/es/layout/layout";
import ProfileHeader from "../../components/ProfileHeader";
import { ExclamationCircleFilled } from "@ant-design/icons";

function ProfileTabs() {
  const { instance } = useMsal();
  const { confirm } = Modal;
  const location = useLocation();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [defaultTab, setDefaultTab] = useState("1");
  let activeAccount;
  let loggedUser;
  var userData;
  let isSameAdminUser = false;

  if (instance) {
    activeAccount = instance.getActiveAccount();
    loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
  }

  if (location.pathname === "/profile/edit") {
    userData = location.state.userData;
  } else {
    //GET USER DATA FROM LOGGED IN ACCOUNT
    loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
    userData = {
      ...loggedUser,
      email: activeAccount.username,
    };
    isSameAdminUser = true
  }
  //HANDLE TAB CHANGE
  const onChange = (key) => {
    if (!isSaveDisabled) {
      showConfirm(key);
      return;
    } else {
      console.log(key);
      setDefaultTab(key);
    }
  };

  //CONFIRM MODAL
  const showConfirm = (key) => {
    confirm({
      className: "confirmModalStyle",
      centered: true,
      icon: <ExclamationCircleFilled />,
      content: "Do you want to proceed without saving changes?",
      okText: "Yes",
      cancelText: "No",
      onOk() {
        console.log("ok");
        setIsSaveDisabled(true);
        setDefaultTab(key);
      },
      onCancel() {
        console.log("Cancel");
      },
      width: 500,
    });
  };
  //TABS
  const items = [
    {
      key: "1",
      label: "Account Details",
      children: (
        <AccountDetails
          userData={userData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <CgDetailsMore />,
    },
    {
      key: "2",
      label: "Personal Information",
      children: (
        <PersonalInformation
          userData={userData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <MdAccountCircle />,
    },
    {
      key: "3",
      label: "Membership",
      children: (
        <Membership
          userData={userData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <MdCardMembership />,
    },
    {
      key: "4",
      label: "Address",
      children: (
        <ShippingDetails
          userData={userData}
          isSaveDisabled={isSaveDisabled}
          setIsSaveDisabled={setIsSaveDisabled}
          loggedUser={loggedUser}
          isSameAdminUser={isSameAdminUser}
        />
      ),
      icon: <ImAddressBook />,
    },
  ];
  return (
    <>
      <Header className="profileMenuBar">
        <ProfileHeader userData={userData} />
      </Header>
      <Tabs
        items={items}
        onChange={onChange}
        activeKey={defaultTab}
        defaultActiveKey={defaultTab}
      ></Tabs>
    </>
  );
}

export default ProfileTabs;
